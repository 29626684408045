//jQuery to collapse the navbar on scroll
var navbar = $(".navbar-fixed-top");

$(window).scroll(function () {
    if (navbar.offset().top > 1) {
        navbar.addClass("top-nav-collapse");
    } else if ($(window).width() > 768) {
        navbar.removeClass("top-nav-collapse");
    }
});

$(window).resize(function () {
    if ($(window).width() < 769 || navbar.offset().top > 1) {
        navbar.addClass("top-nav-collapse");
    } else {
        navbar.removeClass("top-nav-collapse");
    }
});
